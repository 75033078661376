@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Fauna+One&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.app {
  margin: auto;
}
